@tailwind base;
@tailwind components;
@tailwind utilities;
@import "~antd/lib/style/themes/default.less";
@import "~antd/dist/antd.less";

@import url("https://fonts.googleapis.com/css2?family=Georama:ital,wght@0,300;0,400;0,500;0,700;0,900;1,300;1,400;1,500;1,700;1,900&display=swap");
@import "~antd/lib/style/color/colors";
@primary-color: #a18a6d;

@font-face {
  font-family: Bookerly;
  src: url("./assets/fonts/Bookerly-Regular.ttf");
}
@font-face {
  font-family: Bookerly;
  src: url("./assets/fonts/Bookerly-Bold.ttf");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: Bookerly;
  src: url("./assets/fonts/Bookerly-RegularItalic.ttf");
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: Bookerly;
  src: url("./assets/fonts/Bookerly-BoldItalic.ttf");
  font-weight: 700;
  font-style: italic;
}

@font-family: "Georama", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
  "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
  "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
.site-layout-content {
  min-height: 280px;

  background: #fff;
}
.booker {
  font-family: "Bookerly" !important;
  font-size: 16px !important;
}
.logo {
  float: left;
  width: 220px;
  height: 58px;
  margin: 10px 24px 16px 0;
  background-image: url("./assets/images/logo.png");
  background-size: cover;
  background-repeat: no-repeat;
}
.logo2 {
  float: left;
  width: 180px;
  height: 40px;
  margin: 0;
  background-image: url("./assets/images/logo.png");
  background-size: cover;
  background-repeat: no-repeat;
}
.ant-row-rtl .logo {
  float: right;
  margin: 16px 0 16px 24px;
}
.ant-layout-header {
  padding: 0 15px !important;
}
.ant-menu-item-selected {
  font-weight: 700;
}
.amenu {
  background-color: transparent;
  margin-top: 30px;
}
.amenu .ant-menu-item-selected {
  background-color: white !important;
  border-top: 1px solid #ddd !important;
  border-bottom: 1px solid #ddd !important;
}
.ant-drawer-body {
  padding: 0;
}
.ant-menu-horizontal {
  border-bottom: none;
}
.desktop-sider {
  margin-top: 64px;
  background-color: #fafafa;
}
.mobil-sider {
  position: absolute;
  z-index: 1;
  min-height: 100%;
  border-right: 1px solid #ddd;
  background-color: #f5f7f9;
}
.ant-layout-content {
  background-color: white !important;
}

.footer {
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;

  background-color: #fafafa;
  padding: 15px 0 0 0;
}
.anticon svg {
  display: block;
}
.ant-image-preview-img {
  display: initial !important;
}
.bg404 {
  background-image: url("./assets/images/404.jpg");
}
.home-bg {
  background-image: url("./assets/images/background.jpg");
  background-repeat: repeat;
  background-size: contain;
}
.bg-white {
  background-color: white !important;
}

.ic {
  color: white;
  position: relative;
  display: inline-block;

  align-items: center !important;
  justify-content: center !important;
  text-align: center;
}
.layer {
  background-color: rgba(22, 22, 22, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.hakkinda ul {
  list-style: unset;
  padding: 8px 30px;
}
.hakkinda ol {
  list-style-type: decimal;
  padding: 8px 30px;
}
.hakkinda ul li {
  list-style: disc;
}
.imaj {
  border: 3px solid white;
  box-shadow: 0 0 5px #ddd;
}
.ant-menu-overflow-item {
  font-weight: 600;
}
.ql-editor {
  min-height: 250px;
  background-color: white;
}
.dynamic-delete-button {
  position: relative;
  top: 4px;
  margin: 0 8px;
  color: @red-5;
  font-size: 24px;
  cursor: pointer;
  transition: all 0.3s;
}
.dynamic-delete-button:hover {
  color: @red-7;
}
.player-wrapper {
  position: relative;
  padding-top: 56.25%; /* 720 / 1280 = 0.5625 */
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}
.timeline-Header.timeline-InformationCircle-widgetParent {
  border: 1px solid #eee !important;
  background-color: red !important;
}
.kart .ant-card-actions {
  bottom: 0;
  position: absolute;
  width: 100%;
}
.stats-div {
  text-align: center;
  border: 1px solid #ddd;
  box-shadow: 0 0 10px #eee;
  padding: 20px;
  background-color: white !important;
}
.stat-card {
  border: 1px solid #ddd;
  box-shadow: 0 0 10px #eee;
  width: 100%;
}
.ant-table-thead > tr > th {
  color: @blue-6!important;
}
.Demo__some-network {
  display: inline;
  margin-right: 3px;
}
.react-share__ShareButton svg {
  border-radius: 3px !important;
}
.ql-video {
  width: 100%;
  height: 400px;
}
